import React from "react";
import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import HeroSectionComponent from "../components/HeroSectionComponent";
import OurBrandVertical from "../components/OurBrandVertical";
import BlockImageWithText from "../components/BlockImageWithText";
import BlockLatestProductList from "../components/BlockLatestProductList";
import ProductRange from "../components/ProductRange";
import Contentwithoutsb from "../components/Contentwithoutsb";
import Seo from "../components/SEO";

export default function HomePage({ data }) {
  const {
    hero_cta_text,
    hero_cta_url,
    hero_text,
    hero_title,
    content_image,
    content_text,
    content_title,
    content_cta_text,
    content_cta_url,
    hero_background_image,
  } = data.home;

  return (
    <>
      <Seo
        title='Asian Food Wholesaler'
        description={hero_text}
        location={useLocation()}
      />
      <HeroSectionComponent
        title={hero_title}
        text={hero_text}
        link={hero_cta_url}
        linkText={hero_cta_text}
        heroImage={false}
        heroBackgroundImage={hero_background_image.asset.gatsbyImageData}
      />
      <OurBrandVertical />
      <BlockImageWithText
        contentImage={content_image.asset.gatsbyImageData}
        contentTitle={content_title}
        contentText={content_text}
        contentUrl={content_cta_url}
        contentUrlText={content_cta_text}
      />
      <BlockLatestProductList
        title='latest products'
        link='/our-products'
        linkText='view all products'
        borderColor='var(--grey-light)'
        smallVersion={false}
      />
      <Contentwithoutsb>
        <ProductRange />
      </Contentwithoutsb>
    </>
  );
}

export const query = graphql`
  query HomeQuery {
    home: sanityHomePage {
      hero_cta_text
      hero_cta_url

      hero_text
      hero_title
      content_text
      content_title

      content_cta_url
      content_cta_text

      content_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      hero_background_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
