import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const TextWithImageWrapper = styled.div`
  display: flex;
  width: var(--width-percentage);
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  @media (min-width: 768px) {
    margin: auto;
    padding: 1.8rem 0px;
    max-width: var(--max-width);
    margin-bottom: 5rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const TextWrapperSection = styled.div`
  text-align: center;
  background-color: var(--grey-dark);
  padding: 2.4rem 3.2rem 3.6rem;
  position: relative;
  z-index: 0;

  h3 {
    text-transform: uppercase;
    font-size: 2.1rem;
  }

  @media (min-width: 768px) {
    width: 90%;
    margin-left: auto;
    text-align: left;
    padding-left: 27.5%;
    border-radius: var(--border-regular);
  }

  @media (min-width: 1024px) {
    width: 55%;
    padding-left: 2.5rem;
    padding-right: 3rem;
    margin-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

const TextWrapperButtonSection = styled.div`
  margin-top: 2.5rem;
`;

const ImageWrapperSection = styled.div`
  display: none;
  visibility: hidden;

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    visibility: visible;
    width: 30%;
    height: 18rem;
    overflow: hidden;
    border-radius: var(--border-regular);
    position: absolute;
    z-index: 1;
    margin-top: -2rem;
  }

  @media (min-width: 1024px) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 44%;
    position: relative;
    height: auto;
    margin-top: 0px;
  }
`;

export default function BlockImageWithText({
  contentImage,
  contentTitle,
  contentText,
  contentUrl,
  contentUrlText,
}) {
  return (
    <TextWithImageWrapper>
      <ImageWrapperSection>
        <GatsbyImage image={contentImage} alt={contentTitle} />
      </ImageWrapperSection>
      <TextWrapperSection>
        <h3>{contentTitle}</h3>
        <p>{contentText}</p>
        <TextWrapperButtonSection>
          <Link to={contentUrl} className='black-button'>
            {contentUrlText}
          </Link>
        </TextWrapperButtonSection>
      </TextWrapperSection>
    </TextWithImageWrapper>
  );
}
