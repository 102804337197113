import React from "react";
import styled from "styled-components";
import { useStaticQuery, Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const BrandsListVertical = styled.section`
  width: var(--width-percentage);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 0;
  margin-top: -12rem;
  padding: 1.8rem 2rem;
  border-radius: var(--border-regular);
  overflow: hidden;

  @media (min-width: 768px) {
    display: flex;
    margin-top: -8rem;
    padding-left: 2.4rem;
    padding-right: 0px;
    max-width: var(--max-width);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--white);
    top: 0px;
    left: 0px;

    @media (min-width: 768px) {
      width: 67.5%;
      border-top-right-radius: var(--border-regular);
    }

    @media (min-width: 1024px) {
      width: 75%;
    }
  }

  h2 {
    font-size: 2.8rem;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      text-align: left;
      display: inline-block;
      align-self: flex-start;
    }

    &::after {
      content: "";
      width: 5rem;
      height: 0.4rem;
      background-color: var(--red-dark);
      position: absolute;
      margin: auto;
      bottom: -1rem;
      left: 0px;
      right: 0px;

      @media (min-width: 768px) {
        margin-left: 0px;
      }
    }

    span {
      color: var(--red-dark);
      font-size: 2.2rem;
      font-family: var(--font-quattrocento-regular);
      text-transform: capitalize;
      width: 100%;
      display: block;
      line-height: 1;
      margin: 0px;
    }
  }
`;

const BrandsListVerticalList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex: 1;
  }

  li {
    margin: 1%;
    height: 10rem;
    width: 48%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-regular);

    @media (min-width: 768px) {
      width: 24.5%;
      margin: 0px 0.25%;
    }

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  a {
    display: flex;
    width: 92.5%;
    height: 92.5%;
    margin: auto;
    position: absolute;
    z-index: 1;
    color: var(--white);
    text-transform: uppercase;
    font-family: var(--font-oswald-regular);
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-small);
    transition: color 0.25s ease;

    @media (min-width: 768px) {
      &:hover {
        color: var(--yellow-light);
      }
    }

    &.brand-with-bg {
      background-color: rgba(0, 0, 0, 0.7);
      transition: background-color 0.25s ease;

      @media (min-width: 768px) {
        &:hover {
          background-color: var(--black);
        }
      }
    }
  }
`;

const BrandsListVerticalListButton = styled.li`
  background-color: var(--red-dark);

  transition: background-color 0.25s ease;

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--black);
    }
  }
`;

const BrandsListVerticalParagraph = styled.p`
  display: none;
  visibility: hidden;

  @media (min-width: 768px) {
    display: inline-block;
    visibility: visible;
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    width: 24rem;
    margin-top: 0px;
    margin-right: 2rem;
  }

  a {
    font-family: var(--font-quattrocento-bold);
    color: var(--red-light);
    transition: color 0.25s ease;

    @media (min-width: 768px) {
      &:hover {
        color: var(--black);
      }
    }
  }
`;

const BrandsListVerticalListParagraphWrapper = styled.div`
  z-index: 1;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 4rem;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export default function OurBrandVertical() {
  const { brands, brandText } = useStaticQuery(graphql`
    query {
      brands: allSanityBrand(
        filter: { brand_featured: { eq: true } }
        sort: { fields: brand_featured_order, order: [ASC] }
        limit: 3
      ) {
        nodes {
          id
          brand_slug {
            current
          }
          brand_name

          brand_featured_bg {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }

      brandText: sanitySidebarBrand {
        sidebard_brand_text
      }
    }
  `);

  return (
    <BrandsListVertical>
      <h2>
        <span>our</span> brands
      </h2>
      <BrandsListVerticalListParagraphWrapper>
        <BrandsListVerticalParagraph>
          {brandText.sidebard_brand_text}
        </BrandsListVerticalParagraph>
        <BrandsListVerticalList>
          {brands.nodes.map((brand) => (
            <li key={brand.id}>
              <Link
                to={`/our-brands/${brand.brand_slug.current}`}
                className='brand-with-bg'
              >
                {brand.brand_name}
              </Link>
              <GatsbyImage
                image={brand.brand_featured_bg.asset.gatsbyImageData}
                alt=''
              />
            </li>
          ))}
          <BrandsListVerticalListButton>
            <Link to='/our-brands'>view all</Link>
          </BrandsListVerticalListButton>
        </BrandsListVerticalList>
      </BrandsListVerticalListParagraphWrapper>
    </BrandsListVertical>
  );
}
